import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ResponseI } from '../models/response';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url: string = environment.api_url;
  private usuarioActualObj: BehaviorSubject<ResponseI>;
  public accessToken: Observable<ResponseI>;

  constructor(private http: HttpClient, private router: Router,) {
    this.usuarioActualObj = new BehaviorSubject<ResponseI | any>((localStorage.getItem('usuario')));
    this.accessToken = this.usuarioActualObj;
  }

  public get usuarioActual(): ResponseI {
    return this.usuarioActualObj.value;
  }

  loginService(email: string, password: string) {
    return this.http.post<any>(this.url + `api/login`, { email, password })
      .pipe(map(result => {
        if (result && result.access_token) {
          // Almacenar los detalles del usuario
          sessionStorage.setItem('access_token', result.access_token);
          localStorage.setItem('access_token', result.access_token);
          sessionStorage.setItem('user_role', result.usuario.perfil);
          sessionStorage.setItem('user_name', result.usuario.nombre + ' ' + result.usuario.apellido_paterno);
          sessionStorage.setItem('user_email', result.usuario.email);
          this.usuarioActualObj.next(result);
        }
        console.log('usuarios',result.usuarios, result.usuario.nombre, result.usuario.apellido_paterno)
        return true;
      }));
  }

  logout() {
    this.deleteSessionStorage();
    this.router.navigate(['/login'])
  }

  private deleteSessionStorage() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('token');
    sessionStorage.removeItem('user_role');
    sessionStorage.removeItem('user_name');
    sessionStorage.removeItem('user_email');
  }
}
