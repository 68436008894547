import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Inicio',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '/starter',
    title: 'Dashboard',
    icon: 'mdi mdi-gauge',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Empresa',
    icon: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '',
    title: 'Configuración',
    icon: 'fa fa-building-o',
    class: 'has-arrow',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/config/perfil-empresa',
        title: 'Perfil Empresa',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/config/usuarios',
        title: 'Usuarios',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/config/categoria',
        title: 'Categoria',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/config/materia-prima',
        title: 'Materia Prima',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/config/certificado',
        title: 'Certificado',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  {
    path: '/clientes',
    title: 'Clientes',
    icon: 'mdi mdi-account-multiple-outline',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/proveedores',
    title: 'Proveedores',
    icon: 'mdi mdi-truck',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/compras',
    title: 'Compras',
    icon: 'mdi mdi-shopping',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/inventario',
    title: 'Inventario',
    icon: 'mdi mdi-archive',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/punto-venta',
    title: 'Punto Venta',
    icon: 'mdi mdi-barcode-scan',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/monitores',
    title: 'Monitores',
    icon: 'mdi mdi-laptop',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/facturacion',
    title: 'Facturación',
    icon: 'fa  fa-book',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  }, 
];
